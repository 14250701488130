$dark: black;
$body-bg: #000;
$body-color: #fff;
$ill-yellow: #fec200;

$primary-font: bebas-neue-by-fontfabric;


@import "~bootstrap/scss/bootstrap";

@import url(https://use.typekit.net/uaj8ugz.css);

html {
  background: black !important;
}

body {
  font-family: $primary-font, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  font-size: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black  !important  ;
}

nav {
  font-size: 38px;
  font-weight: 600;
  font-family: $primary-font;
  overflow: hidden;
}

a {
  text-decoration: none;
  transition: all 300ms !important;
  color: var(--light-grey) !important;
}

.nav-link:hover, a > svg:hover {
  color: $ill-yellow !important;
}

nav > button {
  color: white !important;
}

.active {
  color: #43ACE2 !important;
}

#producer-dojo-img {
  width: 100%;
}

.modal-content {
  background-color: $primary;
  border: 5px solid white;
}

.modal-content .close {
  color: white;
}

.transparent {
  background: transparent;
}

@keyframes shrinkBox {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
    opacity: 0;
  }
}

@keyframes expandBox {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
  }
}

.hidden {
  visibility: hidden !important;
}